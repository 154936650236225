import { CrashFileDTO } from '@/api/dto/crash-file.dto';
import { ReportMetadataDTO } from '@/api/dto/report';
import {
  baseURL as rbBaseURL,
  closeCrashFile,
  fetchCrashFile,
} from '@/api/report-builder';
import { alertError } from '@/helpers/alert-error';
import { FetchStates } from '@/helpers/fetch-states';
import router from '@/router';
import { Ref, ref, shallowRef, watch } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';

export interface IntegrationAndReports {
  integration: string;
  reports: ReportMetadataDTO[];
  exportableReports: string[];
}

export function useCrashFileDetail(id: Ref<string>, useWatch : boolean = true) {
  const fetchState = ref<FetchStates>(FetchStates.UNFETCHED);
  const crashFile = shallowRef<CrashFileDTO | null>(null);
  const crashReports = shallowRef<ReportMetadataDTO[] | null>(null)
  const reportsByIntegration = shallowRef<IntegrationAndReports[]>([]);



  const getReportsByIntegration = (mCrashFile: CrashFileDTO) => {
    const reports = mCrashFile.reports;
    if (!reports) return [];

    const integrations: Record<string, IntegrationAndReports> = {};
    const baseExportUrl = `${rbBaseURL}/export/report?reportIds=`;

    reports.forEach(report => {
      if (!(report.integration in integrations))
        integrations[report.integration] = {
          integration: report.integration,
          reports: [],
          exportableReports: [],
        };

      integrations[report.integration].reports.push(report);
      if (report.exporter)
        integrations[report.integration].exportableReports.push(report._id);
    });

    const integrationsArr = Object.values(integrations);

    // integrationsArr.map(integration => {
    //   if (integration.exportableReports.length > 0)
    //     integration.exportUrl = `${baseExportUrl}${integration.exportableReports.join(
    //       ',',
    //     )}`;
    // });

    return integrationsArr;
  };

  const fetchRBCrashFile = async () => {
    if (!id.value) return;

    fetchState.value = FetchStates.FETCHING;

    try {
      const resp = await fetchCrashFile(id.value);
      crashFile.value = resp;

      reportsByIntegration.value = getReportsByIntegration(resp);

      fetchState.value = FetchStates.FETCHED;
    } catch (error) {
      console.error(error);
      alertError(error, 'There was an error fetching the crash file.');
      router.replace({ name: 'ReportsPage' });
    }
  };

  const closeRBCrashFile = async () => {
    if (!id.value) return;

    fetchState.value = FetchStates.FETCHING;

    try {
      const resp = await closeCrashFile(id.value);
      crashFile.value = resp;

      reportsByIntegration.value = getReportsByIntegration(resp);

      fetchState.value = FetchStates.FETCHED;
    } catch (error) {
      console.error(error);
      alertError(error, 'There was an error closing the crash file.');

      fetchState.value = FetchStates.UNFETCHED;
    }
  };
  if (useWatch) {
    const stopWatchingId = watch(id, fetchRBCrashFile);
    onBeforeRouteLeave(stopWatchingId);
  }
  fetchRBCrashFile();

  return {
    fetchState,
    crashFile,
    fetchRBCrashFile,
    closeRBCrashFile,
    reportsByIntegration,
  };
}
