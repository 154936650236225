
import { defineComponent, computed, Ref, ref, onMounted } from 'vue';
import router from '@/router';
import { useCrashFileDetail } from '@/composables/useCrashFileDetail';
import { FetchStates } from '@/helpers/fetch-states';
import {
  baseURL as rbBaseUrl,
  requestCrashFileExport,
  downloadExport,
  requestMultiReportExport,
  requestReportExport,
createAdditionalReport,
} from '@/api/report-builder';
import { getIntegrationName } from '@/api/integration-name-map';
import { UserPrefs } from '@/api/dto/user-prefs';
import { UserPrefsModule } from '@/store/userprefs';
import { convertDateString } from '@/helpers/date-format';
import {useOrgManager} from "@/composables/useOrgManager";
import {CrashFileDTO} from "@/api/dto/crash-file.dto";
const { orgMap } = useOrgManager();

export default defineComponent({
  name: 'CrashFilePage',
  setup() {
    const fileId = computed(
      () => router.currentRoute.value.params['id'] as string,
    );

    const userPrefs: Ref<UserPrefs | null> = ref(null);
    onMounted(async () => {
      userPrefs.value = await UserPrefsModule.getUserPrefs();
    });

    const routeToCrashFiles = () => {
      router.push({ name: 'CrashFilesPage' });
    };

    const routeBack = () => {
      if (window.history.state.back === null) {
        routeToCrashFiles();
      } else {
        router.back();
      }
    };

    const {
      crashFile,
      fetchState,
      reportsByIntegration,
      closeRBCrashFile,
    } = useCrashFileDetail(fileId);

    const tryCloseCrashFile = async () => {
      await closeRBCrashFile();
    };

    const downloadCrashFile = async () => {
      if (!crashFile.value?._id) return;
      const exportReq = await requestCrashFileExport(crashFile.value?._id);
      downloadExport(exportReq.nonce);
    };

    const printCrashFile = async () => {
      if (!crashFile.value?._id) return;
      router.push({ name: 'CrashFilePagePrint', params: { id: crashFile.value?._id } });
    };

    const AddAdditionalReport = async () => {
      if (!crashFile.value?._id) return;
      let report_id =  await createAdditionalReport(crashFile.value?._id);
      router.push({ name: 'ReportPage', params: { id: report_id } });
    };

    const downloadMultiReport = async (reportIds: string[]) => {
      const exportReq = await requestMultiReportExport(reportIds);
      downloadExport(exportReq.nonce);
    };

    const downloadReport = async (reportId: string) => {
      const exportReq = await requestReportExport(reportId);
      downloadExport(exportReq.nonce);
    };

    const overRideTitle = (title: string) => {
      if(!title) return '?';
      return title.replace('AccidentPlan', 'Driver');
    }

    const tagData = (_crashFile: CrashFileDTO | null, _orgMap: typeof orgMap.value) => {
      let data = [];
      let org = _crashFile?.org ? _orgMap[_crashFile.org] : '';
      data.push({name: 'Organization', value: org});
      let values: Record<string, string> = {};
      _crashFile?.tags.forEach((a)=>{values[a.key] = `${a.value}`});
      data.push({name: 'Driver', value: `${values['user_first_name']} ${values['user_last_name']}`});
      data.push({name: 'Email', value: `${values['user_email']}`});
      data.push({name: 'User ID', value: `${values['user_id']}`});
      return data;
    }

    const canAddAdditionalReport = () => {
      console.log('reportsByIntegration',crashFile.value?.reports);
      if(crashFile.value?.reports.length === 0) return true;
      return crashFile.value?.reports.map((a)=>{
        if(a.ui === 'additional_information_ui') return true;
        return false
      }).filter(a=>a== true).length == 0;
    }

    console.log('crashFile',crashFile.value?.reports);

    return {
      tagData,
      overRideTitle,
      routeBack,
      routeToCrashFiles,
      fileId,
      crashFile,
      reportsByIntegration,
      closeRBCrashFile,
      tryCloseCrashFile,
      fetchState,
      FetchStates,
      rbBaseUrl,
      getIntegrationName,
      downloadCrashFile,
      printCrashFile,
      userPrefs,
      convertDateString,
      downloadMultiReport,
      downloadReport,
      orgMap,
      canAddAdditionalReport,
      AddAdditionalReport
    };
  },
});
